import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = `${PortalCooperadoUtils.apiCooperado}`;
const RESOURCE_V1 = `/v1/contrato`;

class ContratoService {

    getContrato(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/resumo/" + matricula);
    }

    getDetalheContratos(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/recibo-deposito/" + matricula);
    }

    getDetalhePermuta(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/permuta/" + matricula);
    }

    getDetalhePrecoFixo(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/preco-fixo/" + matricula);
    }

    getDetalheFornecimentoFuturo(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/fornecimento-futuro/" + matricula);
    }

    getDetalheOpcaoVenda(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/opcao-venda/" + matricula);
    }

    getDetalheBloqueioJudicial(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/bloqueio-judicial/" + matricula);
    }
}

export default new ContratoService();
