<template>
    <div class="grid p-fluid">
		<div class="col-12 lg:col-6">
			<div class="card flex flex-column orange" style="min-height: 100%;">
				<h5 class="align-self-start orange">Produtos a fixar</h5> 
                <hr/>
                <div class="card flex flex-column align-items-center">
                    <Chart type="pie" :data="graficoSafra" style="width: 50%" />
                </div>

                <DataTable :value="todosResumoFamilia" :scrollable="true" class="p-datatable-gridlines bold mt-3"> 
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>
                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>
                    <Column field="descricaoFamilia" header="Produto">
                        <template #body="data">
                            {{data.data.descricaoFamilia}}					
                        </template>
                    </Column>
                    <Column field="quantidadeSacas" header="Total SC" style="max-width:12rem">
                        <template #body="data">
                            {{formatDecimal(data.data.quantidadeSacas)}}					
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

        <div class="col-12 lg:col-6">
			<div class="card flex flex-column green blue" style="min-height: 100%;">
				<h5 class="align-self-start blue">Financeiro</h5> 
                <hr/>
                <div class="card flex flex-column align-items-center">
                    <Chart type="doughnut" :data="graficoFinanceiro" style="width: 50%" />               
			    </div>

                <div class="card col-12  p-3">
                    <div class="box-duo bold orange p-3 border radius-4 m-1">
                        <label>Saldo a pagar:</label>       
                        <span class="right">{{formatCurrency(totalContasAPagar)}}</span> 
                    </div>

                    <div class="box-duo bold green p-3 border radius-4 m-1">
                        <label>Saldo a receber:</label>       
                        <span class="right">{{formatCurrency(totalContasAReceber)}}</span> 
                    </div>
                </div>            
            </div>
        </div>
    </div>

    <div class="grid p-fluid">
		<div class="col-12 lg:col-6">
            <div class="card flex flex-column green bold" style="min-height: 100%;">
                <h5>Pedidos</h5>
                <hr/>
                <DataTable :value="todosPedidos" :scrollable="true" class="p-datatable-gridlines bold mt-3"> 
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>
                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>
                    <Column field="numeroPedido" header="Número" style="max-width:12rem" :frozen="idFrozen">
                        <template #body="data">
                            {{data.data.numeroPedido}}
                        </template>
                    </Column>
                    <Column field="dataImplantacao" header="Data" :style="{width:'100%'}" :frozen="idFrozen">
                        <template #body="data">
                            {{formatDate(data.data.dataImplantacao)}}
                        </template>
                    </Column>                                    
                    <Column field="valorTotal" header="Valor" style="max-width:12rem" :frozen="idFrozen">
                        <template #body="data">
                            <span class="red center">{{formatCurrency(data.data.valorTotal)}}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
		</div>

        <div class="col-12 lg:col-6">
			<div class="card flex flex-column green bold" style="min-height: 100%;">
				<h5 class="align-self-start">Contratos</h5> 
                <hr/>
                <DataTable :value="todoResumoContrato" :scrollable="true" class="p-datatable-gridlines bold mt-3">  
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>
                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>
                    <Column field="tipo" header="Tipo" style="max-width:10rem; font-size: 15px;">
					<template #body="slotProps">
						{{slotProps.data.tipo}}
					</template>
				</Column>  
				<Column field="codigoFamilia" header="Produto" style="font-size: 13px;">
					<template #body="slotProps">
						{{slotProps.data.codigoFamilia}}
					</template>
				</Column>
                <Column field="dataVencimento" header="Vencimento" style="max-width:8rem">
					<template #body="slotProps">
						{{formatDate(slotProps.data.dataVencimento)}}
					</template>
				</Column>
                <Column field="safra" header="Safra" style="padding: 2px; font-size: 13px;">
					<template #body="slotProps">
						{{slotProps.data.safra}}
					</template>
				</Column>
                </DataTable>  
			</div>
		</div>
	</div>
</template>

<script>
    import ExtratoResumidoService from "../../service/ExtratoResumidoService"
    import ProdutosFixarService from "../../service/ProdutosFixarService"
    import ContratoService from '../../service/ContratoService'
    import PedidoService from '../../service/PedidoService'
    import Formatacao from "../../utilities/Formatacao"
    

    export default {
        name: 'Extrato Resumido',
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

        data() {
            return {
                /*Graficos web*/
                graficoSafra: null,
                graficoFinanceiro: null,

                /*Contas a pagar*/
                totalContasAPagar: null,
                totalContasAReceber: null,

                /*Componentes*/
                extrato: [],
                todosPedidos: [],
                todoResumoContrato: [],                
                todosResumoFamilia: [],

                /* Popula graficos*/
                dadosFinanceitos: [],
                dadosSafraTitle: [],
                dadosSafraValor: [],
				idFrozen: false,
            }
        },

        created() {            
			this.getPedidos();
            this.getContratos();
            this.getProdutoFixar();
            this.getExtratoResumido();            
        },
        
        methods: {
            getPedidos(){
                PedidoService.getPedidos(this.$props.matricula).then((response) => {
                    this.todosPedidos = response.data;
                });
            },

            getContratos(){
                ContratoService.getContrato(this.$props.matricula).then((response) => {                   
                    this.todoResumoContrato = response.data;					
                });
            },

            getProdutoFixar() {
                ProdutosFixarService.getProdutosFixarResumoFamilia(this.$props.matricula)
                .then((response) => {
                    this.todosResumoFamilia = response.data.content;
                    if (this.todosResumoFamilia != null) {
                        this.todosResumoFamilia.forEach(todosResumoFamilia => {
                            this.dadosSafraTitle.push(todosResumoFamilia.descricaoAbreviada);
                            this.dadosSafraValor.push(todosResumoFamilia.quantidadeQuilos);                        
                        });
                    }
                    
                    this.refreshChart();
				}) ;
			},

            getExtratoResumido() {
                ExtratoResumidoService.getExtratoResumido(this.$props.matricula).then((response) => {
                    this.extrato = response.data;
                    this.totalContasAPagar = response.data.totalContasAPagar;
                    this.totalContasAReceber = response.data.totalContasAReceber;                     
                    this.dadosFinanceitos = [this.totalContasAReceber, this.totalContasAPagar];
                    this.refreshChart();
				});
			},

            getSafra() {
                const {greenColor, orangeColor} = this.getColors();
                const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                return {                    
                    labels: this.dadosSafraTitle,
                    datasets: [
                        {
                            data: this.dadosSafraValor,
                            backgroundColor: [                               
                                greenColor,
                                orangeColor
                            ],
                            borderColor
                        }],
                };               
            },

            getFinanceiro() {
                const {greenColor, orangeColor} = this.getColors();
                const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                return {                    
                    labels: ['A Receber', 'A Pagar'],
                    datasets: [
                        {
                            data: this.dadosFinanceitos,
                            backgroundColor: [                               
                                greenColor,
                                orangeColor
                            ],
                            borderColor
                        }],
                };
            },

            getColors() {
                const isLight = this.$appState.layoutMode === 'light';
                return {
                    pinkColor: isLight ? '#EC407A' : '#F48FB1',
                    purpleColor: isLight ? '#AB47BC' : '#CE93D8',
                    deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
                    indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
                    blueColor: isLight ? '#42A5F5' : '#90CAF9',
                    lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
                    cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
                    tealColor: isLight ? '#26A69A' : '#80CBC4',
                    greenColor: isLight ? '#66BB6A' : '#A5D6A7',
                    lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
                    limeColor: isLight ? '#D4E157' : '#E6EE9C',
                    yellowColor: isLight ? 'FFEE58' : '#FFF59D',
                    amberColor: isLight ? '#FFCA28' : '#FFE082',
                    orangeColor: isLight ? '#FFA726' : '#FFCC80',
                    deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
                    brownColor: isLight ? '#8D6E63' : '#BCAAA4'
                }
            },

            refreshChart() {                
                this.graficoSafra = this.getSafra();
                this.graficoFinanceiro = this.getFinanceiro();
            },

            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			},
            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                if(value == null){
                    value = 0;
                }
                return value.toLocaleString('pt-BR', {style: 'decimal', currency: 'BRL', 
                    minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits});
            }
        }
    }
</script>

<style lang="scss" scoped>   
    @import '../../assets/css/master.scss';       
</style>