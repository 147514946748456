<template>
    <ExtratoResumido :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    import ExtratoResumido from '../../components/extrato/ExtratoResumido.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ExtratoResumido }
    }

</script>