import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = `${PortalCooperadoUtils.apiCooperado}`;
const RESOURCE_V1 = `/v1/pedidos`;

class PedidosService {

    getPedidos(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/resumido/" + matricula);
    }

    getDetalhePedido(matricula, numeroPedido) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + "/detalhado/" + matricula + "/" + numeroPedido);
    }

    getPedidosFiltro(matricula, safra, cultura) {
        let params = {
            safra: safra,
            cultura: cultura
        }
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}`, {params});
    }
}

export default new PedidosService();
